/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, ContactForm, Text, Button } from '@swp/components'
import SiteHeader from '../../components/en/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Rezervace"}>
        <SiteHeader />

        <Column className="css-zy8msw --center pb--30 pt--60" name={"intro"} style={{"backgroundColor":"var(--color-supplementary)"}} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/142/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/142/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/142/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/142/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/142/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/142/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/142/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/142/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":860}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--62 pb--25 pt--25" content={"<span style=\"color: white;\">Booking</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" name={"content"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" style={{"maxWidth":1360}} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <ContactForm className="--shape2 --style1 ff--2 fs--16 w--300" style={{"maxWidth":740}} action={"/contact"} fields={[{"name":"Full name","type":"text","required":true,"placeholder":"Enter your full name"},{"name":"Contact e-mail address:","type":"email","required":true,"placeholder":"Enter your e-mail"},{"name":"Telephone number","type":"text","placeholder":"Telephone No."},{"name":"Message","type":"textarea","required":true,"placeholder":"Enter your message"},{"name":"Send","type":"submit"}]}>
              </ContactForm>

              <Text className="text-box" content={"510-851-40X4<br>info@vase-stranky.com<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--60 pt--60" name={"footer"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --center el--3 flex--center" columns={"3"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"Address<br>"}>
              </Title>

              <Text className="text-box" content={"Hotel Grande<br>722 Finch Street<br>USA"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box fs--43 swpf--uppercase ls--0 lh--1" content={"Hotel<br>Grande<br>"}>
              </Title>

              <Button className="btn-box fs--16 swpf--uppercase" content={"Available rooms"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--right">
              
              <Title className="title-box" content={"Contact<br>"}>
              </Title>

              <Text className="text-box" content={"510-851-40X4<br>info@vase-stranky.com<br>Created with <a href=\"https://saywebpage.com\">saywebpage.com</a><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}